<template>
  <div>
    <!-- 公共头部 -->
    <Header2></Header2>
    <div class="container">
      <router-view></router-view>
    </div>
    <!-- 公共底部 -->
    <Footer></Footer>
    <!-- 右侧固定 -->
    <RightSide></RightSide>
  </div>
</template>
<script>
import Header2 from "@/components/base/header2.vue";
import Footer from "@/components/base/footer.vue";
import RightSide from "@/components/base/rightSide.vue";
export default {
  components: {
    Header2,
    Footer,
    RightSide,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.container {
  min-width: 1200px;
  min-height: calc(100vh - 300px);
  // margin-top: 120px;
  padding-top: 60px;
  box-sizing: border-box;
  background-color: #f2f2f2;
}
</style>